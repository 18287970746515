import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Documentation",
  "slug": "documentation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is the documentation for enableHR Open API for Business / Product people.`}</p>
    <p>{`It aims at assisting non-technical people from the Business / Product world to understand what can be achieved with enableHR Open API. It is written in plain English with limited to no technical jargon. It does not include the general definition of an API and it doesn’t talk about the existing integrations that have already been built using the API (see `}<a href="https://www.enablehr.com.au/integration-api/" target="_blank">{`here`}</a>{`).`}</p>
    <p>{`An API (Application Programming Interface) is a bunch of software code that allows two applications to communicate with one another to access data. Open APIs are available for use by anyone and are typically offered as a way to extend features.`}</p>
    <p>{`Obviously, this is all done in a secured and controlled manner.`}</p>
    <p>{`In some cases, use of Open APIs is free, while others require membership or payment.`}</p>
    <p>{`At the present moment, there is no additional cost to use enableHR’s API as it is included in a Licence Agreement. There is no immediate plan to change this.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      